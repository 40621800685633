import * as React from "react"
// import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import { Container, Row, Col } from "react-bootstrap"

const IndexPage2 = () => {
  const { placeholderImage123 } = useStaticQuery(
          graphql`
      query {
        placeholderImage123: file(relativePath: { eq: "contact-us.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 50, 
              webpOptions: {quality: 50}, 
              width: 2000,
              placeholder: BLURRED,
              blurredOptions: {width: 1000},
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const pluginImage = getImage(placeholderImage123);
  // const crowdImage = getImage(crowd);

  return (
    <Layout>
      <SEO title="Contact" />
      <BgImage image={pluginImage} className="subhead">
        <div className="color-overlay d-flex pt-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <h1>Get in Touch!</h1>
              
            </Container>
          </div>
        </div>
      </BgImage>
      <Container className="py-5">
        <Row>
          <Col lg={6} className="px-4 px-xs-0">
            <p>We would love to talk to you about your next event so give us a call – we’re a lot of fun to talk to. Or send us an email if chatting isn’t your thing.</p>
            <p><strong>Phone:</strong> 415.451.1980</p>
            <p><strong>Email:</strong> <a href="mailto:">info@eci-events.com</a></p>
          </Col>
          {/* <Col lg={6} className="mx-xs-2">
            <Form 
              name="contact v2"
              method="post"
              action="/contact-success/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit="submit"
              className="greyRoundBorder"
            >
              <input type="hidden" name="form-name" value="contact v2" />
              <p hidden>
              <label>
                  Don’t fill this out: <input name="bot-field" />
              </label>
              </p>
              <Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control 
                            type="text" name="first-name"
                          />
                    </Form.Group>
                </Col>
                <Col md={6}>
                <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control 
                            type="text" name="last-name"

                          />
                </Form.Group>
                </Col>
            </Row>


                
            <Form.Group>
                <Form.Label>Email address</Form.Label>
                <Form.Control 
                    required
                    type="email" 
                    name="email" 
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>How can we help you?</Form.Label>
                <Form.Control 
                    as="textarea" 
                    name="how-can-we-help-you"
                    rows="5"
                  />
            </Form.Group>
            <button type="submit" className="btn btn-outline-dark">Send Message</button>
            </Form>
          </Col> */}
        </Row>
      </Container>
      {/* <BgImage image={crowdImage}>
        Test
        <div className="color-overlay d-flex py-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <p className="lead"><em>"Safely Processing and Delivering Superior Value and Service to Our Customers for over 100 years."</em></p>
            </Container>
          </div>
        </div>
      </BgImage> */}
      {/* crowd.jpg */}
    </Layout>
          
  )
}

export default IndexPage2